import useInspections from "../../backend/hooks/useInspections.js";
import { Layout } from "../../components/Layout/index.jsx";
import { SectionFilters } from "../../components/filters/SectionFilters.jsx";
import { InspectionList } from "./InspectionList.jsx";
import { Warning } from "../../components/Warnings/index.jsx";
import { useUrlState } from "../../state/useUrlState.js";
import { Box, Checkbox, VStack } from "@chakra-ui/react";
import { stringToBoolean } from "../../utils/stringToBoolean.js";
import { FilterWrapper } from "../../components/filters/FilterWrapper.jsx";
import "react-medium-image-zoom/dist/styles.css";
import { CommentFilter } from "../../components/filters/CommentFilter.jsx";
import { curry, isDefined } from "@nextml/lodestar";
import { useLimitAndOffset } from "../../state/useLimitAndOffset.js";

const missingCommentFilter = (image) => !image.inspection.internal_comment;
const notMarkedForReviewFilter = (image) => image.inspection.official === false;

const uncheckedFilter = curry(
  (filterNotMarked, filterMissingComment, image) => {
    if (filterMissingComment && filterNotMarked) {
      return missingCommentFilter(image) && notMarkedForReviewFilter(image);
    }
    if (filterMissingComment && !filterNotMarked) {
      return missingCommentFilter(image);
    }
    if (!filterMissingComment && filterNotMarked) {
      return notMarkedForReviewFilter(image);
    }
    return true;
  }
);

const positionFilter = (filterHasPosition, image) => {
  if (filterHasPosition) {
    return isDefined(image.closest_position);
  } else {
    return true;
  }
};

const filters =
  (filterNotMarked, filterMissingComment, filterHasPosition) => (image) => {
    return (
      uncheckedFilter(filterNotMarked, filterMissingComment, image) &&
      positionFilter(filterHasPosition, image)
    );
  };

const UncheckedWarning = ({ inspectionImages, filter }) => {
  if (inspectionImages.error) {
    return null;
  }

  const uncheckedInspections = inspectionImages?.data
    ?.filter(filter)
    .filter(uncheckedFilter(true, true));

  if (!inspectionImages.isLoading && uncheckedInspections.length > 0) {
    return (
      <Warning>
        {uncheckedInspections.length} inspections are either not marked for
        official review or missing an internal comment.
      </Warning>
    );
  }

  return null;
};

const Sidebar = ({
  inspectionImages,
  filter,
  filterNotMarked,
  filterMissingComment,
  filterHasPosition,
}) => {
  return (
    <>
      <CommentFilter urlStateKey="internalCommentText" />

      <FilterWrapper>
        <VStack align="left">
          <Checkbox
            size="sm"
            value={stringToBoolean(filterNotMarked.data)}
            onChange={(event) => filterNotMarked.set(event.target.checked)}
          >
            Not marked for official review
          </Checkbox>
          <Checkbox
            size="sm"
            value={stringToBoolean(filterMissingComment.data)}
            onChange={(event) => filterMissingComment.set(event.target.checked)}
          >
            Missing comment
          </Checkbox>
          <Checkbox
            size="sm"
            value={stringToBoolean(filterHasPosition.data)}
            onChange={(event) => filterHasPosition.set(event.target.checked)}
          >
            Has positions
          </Checkbox>

          <UncheckedWarning
            inspectionImages={inspectionImages}
            filter={filter}
          />
        </VStack>
      </FilterWrapper>

      <SectionFilters official={false} />
    </>
  );
};

export const InternalReview = () => {
  const filterNotMarked = useUrlState("filterNotMarked", "false");
  const filterMissingComment = useUrlState("filterMissingComment", "false");
  const filterHasPosition = useUrlState("filterHasPosition", "false");
  const filter = filters(
    stringToBoolean(filterNotMarked.data),
    stringToBoolean(filterMissingComment.data),
    stringToBoolean(filterHasPosition.data)
  );

  const { data: trackSection } = useUrlState("trackSection");
  const { data: kilometer } = useUrlState("kilometer");
  const { data: meter } = useUrlState("meter");
  const { data: trackLane } = useUrlState("trackLane");
  const { data: internalCommentText } = useUrlState("internalCommentText");
  const { data: actionTypes } = useUrlState("actionTypes", []);
  const limitAndOffset = useLimitAndOffset();

  const inspectionImages = useInspections({
    trackSection,
    kilometer,
    meter,
    trackLane,
    internalCommentText,
    actionTypes,
    lookupPossiblyFixed: true,
    sortBy: "inspection.created",
    ...limitAndOffset,
  });

  return (
    <Layout
      sidebar={
        <Sidebar
          inspectionImages={inspectionImages}
          filter={filter}
          filterNotMarked={filterNotMarked}
          filterMissingComment={filterMissingComment}
          filterHasPosition={filterHasPosition}
        />
      }
    >
      <Box width="100%">
        <InspectionList
          width="full"
          inspectionImages={inspectionImages}
          filter={filters(
            stringToBoolean(filterNotMarked.data),
            stringToBoolean(filterMissingComment.data),
            stringToBoolean(filterHasPosition.data)
          )}
        />
      </Box>
    </Layout>
  );
};
