import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export const useNavigate = () => {
  const history = useHistory();

  const to = useCallback(
    (path, options) => {
      if (options?.replace) {
        const { _, ...otherOptions } = options;
        return history.replace(path, otherOptions);
      }
      return history.push(path, options);
    },
    [history]
  );

  return { to };
};
