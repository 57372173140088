import React from "react";
import { ContextProvider } from "./store/ContextProvider";
import { isDefined } from "./core/isDefined";
import { initialState } from "./store/initialState";
import { useFileUploadBatchControl } from "./hooks/useFileUploadBatchControl";
import { useFileUpload } from "./hooks/useFileUpload";
import { FileDropzone } from "./components/FileDropzone/FileDropzone";
import { FileList } from "./components/FileList/index";
import { validateId } from "./validation/propsValidation";
import { theme } from "./theme";
const wrapperStyle = {
    border: `1px solid ${theme.border.color.primary}`,
    borderRadius: theme.border.radius.primary,
    backgroundColor: "#ffffff",
    width: "100%",
};
const validateRequestOptions = (requestOptions) => {
    if (isDefined(requestOptions.body) && isDefined(requestOptions.form)) {
        throw new Error("Specifying both requestOptions.body and requestOptions.form is a contradiction.");
    }
    if (isDefined(requestOptions.form)) {
        if (Array.isArray(requestOptions.form)) {
            throw new Error("requestOptions.form is an array.");
        }
        if (typeof requestOptions.form !== "object") {
            throw new Error("requestOptions.form is not an object.");
        }
        Object.keys(requestOptions.form).forEach((key) => {
            if (typeof requestOptions.form[key] === "object") {
                throw new Error(`requestOptions.form[${key}]: Nested form not supported yet.`);
            }
        });
    }
};
const Root = ({ url, onUploadResponse, onRowClick, requestOptions = {}, id, }) => {
    validateId(id);
    validateRequestOptions(requestOptions);
    useFileUploadBatchControl();
    useFileUpload(url, onUploadResponse, requestOptions);
    return (React.createElement("div", { style: wrapperStyle },
        React.createElement(FileDropzone, null),
        React.createElement(FileList, { onRowClick: onRowClick })));
};
export const FileUpload = ({ requestBatchSize = 1, loglevel, ...props }) => {
    return (React.createElement(ContextProvider, { loglevel: loglevel, initialState: { ...initialState, requestBatchSize } },
        React.createElement(Root, { ...props })));
};
