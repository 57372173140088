import { useState } from "react";

import { isDefined } from "@nextml/lodestar";

import { AspectRatio, Image, Skeleton } from "@chakra-ui/react";
import { Stage, Layer, Circle } from "react-konva";

import useImage from "../../backend/hooks/useImage";
import useImageData from "../../backend/hooks/useImageData";
import useModels from "../../backend/hooks/useModels";
import { TARGETS } from "./annotationTypes";
import { onEvent } from "../../utils/onEvent";

export const WireImage = ({
  imageId,
  targetType,
  fallbackData,
  children,
  displayPredictions,
}) => {
  const image = useImage(imageId);
  const imageData = useImageData(imageId, { fallbackData });
  const [hover, setHover] = useState(false);

  const { data: models, errorModels } = useModels();
  const modelTrainedDate =
    models === undefined && !errorModels
      ? {}
      : Object.assign(
          {},
          ...models.map((model) => ({
            [model.id]: new Date(model.trained),
          }))
        );

  const latestPrediction = image.data?.predictions
    ?.filter((prediction) => TARGETS[targetType].includes(prediction.target))
    ?.sort((predictionA, predictionB) => {
      if (
        modelTrainedDate[predictionA.model_id] ===
        modelTrainedDate[predictionB.model_id]
      ) {
        return predictionA.probability < predictionB.probability ? 1 : -1;
      } else {
        return modelTrainedDate[predictionA.model_id] <
          modelTrainedDate[predictionB.model_id]
          ? 1
          : -1;
      }
    })[0];

  return (
    <AspectRatio
      minWidth="100%"
      ratio={3.3}
      onMouseEnter={onEvent(setHover, true)}
      onMouseLeave={onEvent(setHover, false)}
    >
      <Skeleton
        isLoaded={!image.isLoading && !imageData.isLoading}
        startColor="gray.300"
        endColor="gray.400"
        position={"relative"}
      >
        <Image
          src={imageData.data}
          style={{ objectFit: "fill", height: "100%", width: "100%" }}
          alt={`${image.data?.measurement_name}/${image.data?.frame_index}/${image.data?.camera_index}`}
        />

        {displayPredictions ? (
          <Prediction prediction={latestPrediction} />
        ) : null}

        {hover ? children : null}
      </Skeleton>
    </AspectRatio>
  );
};

const Prediction = ({ prediction }) => {
  const [ref, setRef] = useState(null);

  const width = ref ? ref.offsetWidth : 0;
  const height = ref ? ref.offsetHeight : 0;

  if (!isDefined(prediction?.x, prediction?.y)) {
    return null;
  }

  return (
    <div
      ref={setRef}
      style={{
        top: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
    >
      <Stage width={width} height={height}>
        <Layer>
          <Circle
            x={prediction.x * width}
            y={prediction.y * height}
            radius={30}
            stroke="red"
            opacity={0.5}
          />
        </Layer>
      </Stage>
    </div>
  );
};
