import React from "react";
import { FAIL, PENDING } from "../core/constants";
import { SET_FILE_STATUS } from "../store/actionTypes";
import { theme } from "../theme";
const retry = (dispatch, fileId) => (_) => {
    dispatch({
        type: SET_FILE_STATUS,
        payload: {
            id: fileId,
            status: PENDING,
        },
    });
};
const buttonStyle = {
    color: theme.color.blue,
    cursor: "pointer",
    textDecoration: "underline",
    marginLeft: "7px",
    border: "none",
    backgroundColor: "transparent",
    fontSize: "14px",
};
const RetryButton = ({ status, fileId, dispatch }) => {
    if (status === FAIL) {
        return (React.createElement("button", { onClick: retry(dispatch, fileId), style: buttonStyle }, "Retry"));
    }
    return null;
};
export default RetryButton;
