import { Box } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { KW6Image } from "./KW6Image";
import { ImageScroll } from "../ImageScroll/index.jsx";

export const ZoomImage = ({
  imageData,
  initialImageData,
  isZoomed,
  children,
  measurementName,
  ...props
}) => {
  const focusRef = useRef(null);

  useEffect(() => {
    if (focusRef.current && isZoomed) {
      focusRef.current.focus();
    }
  }, [focusRef, isZoomed]);

  if (isZoomed) {
    return (
      <Box
        position="fixed"
        top="0"
        left="0"
        padding={5}
        width="100%"
        height="100vh"
        tabIndex="0"
        backgroundColor={"blackAlpha.400"}
        ref={focusRef}
        zIndex="overlay"
      >
        <Box
          backgroundColor="gray.50"
          width="100%"
          height="100%"
          borderRadius="md"
          padding={5}
          {...props}
        >
          {children}
          <ImageScroll measurementName={measurementName} />
        </Box>
      </Box>
    );
  }

  return (
    <KW6Image
      isLoading={initialImageData.isLoading}
      error={initialImageData.error}
      src={initialImageData?.data}
      width="460px"
    />
  );
};
