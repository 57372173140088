import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import {
  Center,
  Flex,
  Heading,
  useColorModeValue,
  Divider,
  Image,
  Spacer,
} from "@chakra-ui/react";
import { NavLink } from "./NavLink";
import { NavigationMenu } from "./NavigationMenu";

const NavDivider = () => (
  <Center height="65px" marginRight="5">
    <Divider orientation="vertical" height="30px" />
  </Center>
);

const InternalPages = () => {
  const { keycloak } = useKeycloak();

  if (keycloak.realmAccess.roles.includes("internal")) {
    return (
      <>
        <NavDivider />
        <NavLink name="Internal review" link="/internal-review" />
        <NavLink name="Measurements" link="/measurements" />
      </>
    );
  }
  return null;
};

const Links = () => {
  const { keycloak } = useKeycloak();

  if (keycloak.authenticated) {
    return (
      <Flex marginLeft="auto" alignItems="stretch">
        <NavLink name="Damages" link="/damages" roles={["all"]} />
        <NavLink name="Search" link="/image-search" roles={["all"]} />
        <NavLink name="Metrics" link="/inspection-metrics" roles={["all"]} />
        <NavLink name="Upload Positions" link="/positions" roles={["all"]} />
        <InternalPages />
      </Flex>
    );
  }

  return null;
};

export const NavBar = () => {
  return (
    <Flex
      width="100%"
      paddingRight="10"
      paddingLeft="10"
      position="sticky"
      zIndex="docked"
      top="0"
      left="0"
      bg={useColorModeValue("white", "gray.900")}
      borderBottom="1px solid"
      borderColor="blackAlpha.100"
      direction="row"
      height={"64px"}
    >
      <Spacer />

      <Flex
        direction="row"
        alignItems="center"
        maxWidth="container.xl"
        width="full"
      >
        <Image src="logo.png" width="44px" marginRight="3" />
        <Heading size="sm">Deep Inspection - Wire</Heading>
        <Links />
      </Flex>

      <Flex flex="1" alignItems="center">
        <NavigationMenu />
      </Flex>
    </Flex>
  );
};
