import React from "react";
import { PENDING, UPLOADING } from "../../core/constants";
import { theme } from "../../theme";
const progressbarStyle = {
    width: "100%",
    height: "3px",
    display: "flex",
    marginTop: "5px",
    borderRadius: "100px",
};
const doneStyle = (progress) => ({
    width: `${progress}%`,
    backgroundColor: theme.color.blue,
});
const leftStyle = (progress) => ({
    width: `${100 - progress}%`,
    backgroundColor: theme.color.grey,
});
const ProgressBar = ({ status, progress }) => {
    if ([PENDING, UPLOADING].includes(status)) {
        return (React.createElement("div", { style: progressbarStyle },
            React.createElement("div", { style: doneStyle(progress) }),
            React.createElement("div", { style: leftStyle(progress) })));
    }
    return null;
};
export default ProgressBar;
