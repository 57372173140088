export const theme = {
    font: {
        size: {
            small: "10px",
            regular: "12px",
            medium: "14px",
            big: "16px",
            bigger: "18px",
        },
    },
    color: {
        blue: "#51aded",
        blueLight: "#e6f5fd",
        blueDark: "#259ced",
        grey: "#536270",
        greyLight: "#f3f5f6",
        greyDark: "#4d5863",
        red: "#ed5153",
        redLight: "#fff3f3",
        redDark: "#e0393c",
        green: "#23ce6b",
        greenDark: "#1ec262",
        white: "#ffffff",
        black: "#000000",
        purple: "#201c35",
        purpleDark: "#181528",
    },
    border: {
        color: {
            primary: "#51aded",
            secondary: "#cfecfd",
            tertiary: "#e8eaec",
        },
        radius: {
            primary: "3px",
        },
    },
    text: {
        fadedGrey: "#a2acb3",
        fadedWhite: "#ffffff55",
    },
    spacing: {
        mini: "4px",
        small: "8px",
        regular: "16px",
        medium: "32px",
        large: "64px",
    },
};
