import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";

const path = (measurementName, frameIndex, cameraIndex) =>
  `/images/${measurementName}/${frameIndex}/${cameraIndex}`;

export const useNavigate = () => {
  const history = useHistory();
  const location = useLocation();

  return {
    push: (measurementName, frameIndex, cameraIndex) => {
      history.push(
        `${path(measurementName, frameIndex, cameraIndex)}${location.search}`
      );
    },
    replace: (measurementName, frameIndex, cameraIndex) => {
      history.replace(
        `${path(measurementName, frameIndex, cameraIndex)}${location.search}`
      );
    },
  };
};
