import { Button } from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import { useAuthorizedFetcher } from "../../backend/hooks/useAuthorizedFetcher";

const IN_PROGRESS_STATUS = {
  pending: "pending",
  running: "running",
};

export const ProcessNewPositionsButton = ({ processStatus }) => {
  const authorizedFetcher = useAuthorizedFetcher();
  return (
    <Button
      leftIcon={<RepeatIcon />}
      colorScheme={"green"}
      marginLeft={"auto"}
      onClick={(_) => {
        authorizedFetcher("/position-files/process", {
          method: "post",
        });
        processStatus.mutate(
          {
            ...processStatus.data,
            status: IN_PROGRESS_STATUS.pending,
          },
          false
        );
      }}
      isDisabled={Object.values(IN_PROGRESS_STATUS).includes(
        processStatus?.data?.status
      )}
    >
      {/* TODO: allow the user to differentiate between different statuses */}
      {Object.values(IN_PROGRESS_STATUS).includes(processStatus?.data?.status)
        ? "Processing Position Files..."
        : "Process New Positions"}
    </Button>
  );
};
