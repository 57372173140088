import { Box } from "@chakra-ui/react";
import { ImageScroll } from "../../components/ImageScroll/index.jsx";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { useCallback, useMemo, useRef } from "react";
import { useNavigate } from "../../utils/useNavigate.js";
import { useScrollListener } from "../../utils/useEventListener.js";
import { useDebounce } from "../../utils/useDebounce.js";
import { useCarDirection } from "../Annotate/useCarDirection";

const useMeasurementDataIndex = () => {
  const { measurementName, index } = useParams();
  const carDirection = useCarDirection(measurementName);
  const frameIndexIncrement = carDirection.data === "A" ? -1 : 1;

  const measurementDataIndex = useMemo(
    () => ({
      frameIndexIncrement,
      measurementName,
      index: parseInt(index),
      next: { measurementName, index: parseInt(index) + frameIndexIncrement },

      previous: {
        measurementName,
        index: parseInt(index) - frameIndexIncrement,
      },
      sort: (list) => (frameIndexIncrement > 0 ? list : [...list].reverse()),
    }),
    [measurementName, index, frameIndexIncrement]
  );

  return measurementDataIndex;
};

const Component = () => {
  const navigate = useNavigate();
  const debounce = useDebounce();

  const measurementDataIndex = useMeasurementDataIndex();
  const wrapperRef = useRef(null);

  const onScroll = useCallback(
    (event) => {
      if (event.deltaY > 0) {
        debounce(
          () =>
            navigate.to(MeasurementInspection.at(measurementDataIndex.next), {
              replace: true,
            }),
          { timeout: 50 }
        );
      }

      if (event.deltaY < 0) {
        debounce(
          () =>
            navigate.to(
              MeasurementInspection.at(measurementDataIndex.previous),
              {
                replace: true,
              }
            ),
          { timeout: 50 }
        );
      }
    },
    [measurementDataIndex, debounce, navigate]
  );

  useScrollListener(wrapperRef, onScroll);

  return (
    <Box
      top="64px"
      left={0}
      position="absolute"
      height="calc(100vh - 64px)"
      width="full"
      overflow="hidden"
      backgroundColor="gray.400"
      ref={wrapperRef}
    >
      <ImageScroll measurementDataIndex={measurementDataIndex} />
    </Box>
  );
};

export const MeasurementInspection = {
  Component,
  at: ({ measurementName, index }) => `/inspection/${measurementName}/${index}`,
};
