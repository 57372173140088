import { Skeleton, Heading, Alert, AlertIcon, Flex } from "@chakra-ui/react";
import useImage from "../../backend/hooks/useImage";
import { Loader } from "../../components/Loader.jsx";
import { Position } from "../../components/Position";

export const WireHeading = ({ imageId }) => {
  const image = useImage(imageId);

  if (image.isLoading) {
    return <Loader />;
  }

  const position = image.data?.closest_position;

  return (
    <Flex width="full">
      <Heading size="md">
        {image.data.measurement_name === undefined
          ? "?"
          : image.data.measurement_name}
        <span> / </span>
        {image.data.frame_index === undefined
          ? "?"
          : image.data.frame_index.toFixed(0)}
        <span> / </span>
        {image.data.camera_index === undefined
          ? "?"
          : image.data.camera_index.toFixed(0)}
      </Heading>

      <Skeleton isLoaded={!image.isLoading} marginLeft="auto">
        {!position ? (
          <Alert status="error">
            <AlertIcon />
            Missing positioning data and will be unable to create inspections.
          </Alert>
        ) : (
          <Heading size="md">
            <Position image={image.data} />
          </Heading>
        )}
      </Skeleton>
    </Flex>
  );
};
