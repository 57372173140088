import useImages from "../../backend/hooks/useImages";

export const useCarDirection = (measurementName) => {
  const images = useImages({ measurementName, requiresAnnotation: true });

  return {
    ...images,
    data: images.data?.[0]?.closest_position?.car_direction,
  };
};
