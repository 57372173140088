import styled, { css } from "styled-components";
import { UPLOADING, FAIL, PENDING } from "../../../core/constants";
import { theme } from "../../../theme";
const Wrapper = styled.div `
  padding: ${theme.spacing.small} ${theme.spacing.regular};
  border-top: 1px solid ${theme.border.color.secondary};
  font-size: ${theme.font.size.medium};
  display: flex;
  flex-direction: column;
  :last-child {
    border-bottom-left-radius: ${theme.border.radius.primary};
    border-bottom-right-radius: ${theme.border.radius.primary};
  }
  :hover {
    background-color: ${theme.color.greyLight};
  }
  ${(props) => {
    switch (props.status) {
        case UPLOADING: {
            return css `
          background-color: ${theme.color.blueLight};
        `;
        }
        case PENDING: {
            return css `
          background-color: ${theme.color.greyLight};
          color: ${theme.text.fadedGrey};
        `;
        }
        case FAIL: {
            return css `
          background-color: ${theme.color.redLight};
          color: ${theme.color.grey};
        `;
        }
        default: {
            return css `
          background-color: #ffffff;
        `;
        }
    }
}}
`;
export default Wrapper;
