import { Box, Flex } from "@chakra-ui/react";
import useImages from "../../backend/hooks/useImages";
import { useStitchedImageData } from "../../backend/hooks/useStitchedImageData.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { ImageView } from "../../views/Image/index.js";
import { PositionFooter } from "./PositionFooter.jsx";

// TODO: Make dynamic
const imageHeight = "100px";

const WireImage = ({ imageQuery }) => {
  const image = useImages(imageQuery);
  const { data } = useStitchedImageData(imageQuery);
  const history = useHistory();
  return (
    <Box
      width={"full"}
      height={imageHeight}
      backgroundColor={"gray.600"}
      boxSizing="border-box"
      onClick={() => history.push(ImageView.at(image?.data[0]))}
      position={"relative"}
    >
      <Box
        position={"absolute"}
        top={0}
        left={0}
        width={"full"}
        height={"full"}
        border={"2px dashed transparent"}
        cursor={"pointer"}
        _hover={{ borderColor: "orange.200" }}
      />
      <img
        alt=""
        height={imageHeight}
        style={{ width: "100%", height: "100%" }}
        src={data}
      />
    </Box>
  );
};

const ImageColumn = ({ imageQueries }) => (
  <Flex direction="column" flex={1}>
    {imageQueries.map((query) => (
      <WireImage
        key={`${query.measurementName}_${query.fromFrameIndex}_${query.cameraIndex}`}
        imageQuery={query}
      />
    ))}
  </Flex>
);

const GridWire = ({ measurementName, frameIndexIncrement, sort, index }) => {
  const imageData = useImages({
    measurementName,
    fromFrameIndex: index,
    toFrameIndex: index,
  })?.data?.[0];
  const range = [
    index - 10 * frameIndexIncrement,
    index + 10 * frameIndexIncrement,
  ];
  const imageQueries = [];
  for (
    let frameIndex = Math.min(...range);
    frameIndex <= Math.max(...range);
    frameIndex++
  ) {
    const imageQuery = {
      measurementName,
      fromFrameIndex: frameIndex,
      toFrameIndex: frameIndex,
    };
    imageQueries.push(imageQuery);
  }

  return (
    <>
      <Flex position={"absolute"} top={"50%"} transform={"translateY(-50%)"}>
        <ImageColumn
          imageQueries={sort(imageQueries).map((query) => ({
            ...query,
            cameraIndex: 211,
          }))}
        />
        <ImageColumn
          imageQueries={sort(imageQueries).map((query) => ({
            ...query,
            cameraIndex: 212,
          }))}
        />
      </Flex>
      <PositionFooter imageData={imageData} />
    </>
  );
};

export const ImageScroll = ({ measurementDataIndex }) => (
  <GridWire {...measurementDataIndex} />
);
