import React from "react";
import { UPLOADING, DONE, FAIL } from "../core/constants";
import { faExclamationCircle, faCheck, faSyncAlt, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { theme } from "../theme";
const iconSwitch = (status) => {
    switch (status) {
        case UPLOADING: {
            return (React.createElement(FontAwesomeIcon, { icon: faSyncAlt, style: {
                    color: theme.color.blue,
                    animation: "rotate 1s ease-in-out infinite",
                } }));
        }
        case DONE: {
            return (React.createElement(FontAwesomeIcon, { icon: faCheck, style: { color: theme.color.green } }));
        }
        case FAIL: {
            return (React.createElement(FontAwesomeIcon, { icon: faExclamationCircle, style: { color: theme.color.red } }));
        }
        default: {
            return null;
        }
    }
};
const StatusIcon = ({ status }) => (React.createElement("span", { style: { marginRight: "7px" } }, iconSwitch(status)));
export default StatusIcon;
