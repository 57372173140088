import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme.js";

import "./index.css";

import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/300-italic.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/400-italic.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/500-italic.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/600-italic.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/700-italic.css";
import "@fontsource/open-sans/800.css";
import "@fontsource/open-sans/800-italic.css";

import "react-medium-image-zoom/dist/styles.css";
import AutoToggleColorMode from "./AutoToggleColorMode.jsx";

export const Styles = ({ children }) => {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <AutoToggleColorMode />
      {children}
    </ChakraProvider>
  );
};
