import { useState, useEffect } from "react";
import useImages from "../../backend/hooks/useImages";
import useModels from "../../backend/hooks/useModels";
import { ANNOTATION_TYPES, TARGETS } from "./annotationTypes";

const mostRecentProbability = (image, modelTrainedDate, targetType) => {
  const sortedPredictions = image.predictions
    .filter((prediction) => TARGETS[targetType].includes(prediction.target))
    .sort((predictionA, predictionB) => {
      if (
        modelTrainedDate[predictionA.model_id] ===
        modelTrainedDate[predictionB.model_id]
      ) {
        return predictionA.probability < predictionB.probability ? 1 : -1;
      } else {
        return modelTrainedDate[predictionA.model_id] <
          modelTrainedDate[predictionB.model_id]
          ? 1
          : -1;
      }
    });
  return sortedPredictions.length >= 1 ? sortedPredictions[0].probability : 0.0;
};

export const useProbabilityQueue = ({
  measurementName,
  frameIndex,
  cameraIndex,
  targetType,
}) => {
  const [anchor, setAnchor] = useState(null);
  const images = useImages({
    measurementName,
    targets: TARGETS[targetType],
    requiresAnnotation: true,
  });
  const { data: models, errorModels } = useModels();
  const modelTrainedDate =
    models === undefined && !errorModels
      ? {}
      : Object.assign(
          {},
          ...models.map((model) => ({
            [model.id]: new Date(model.trained),
          }))
        );

  const sortedImages = images.data?.sort((imageA, imageB) => {
    const probabilityA = mostRecentProbability(
      imageA,
      modelTrainedDate,
      targetType
    );
    const probabilityB = mostRecentProbability(
      imageB,
      modelTrainedDate,
      targetType
    );
    if (probabilityA === probabilityB) {
      return 0;
    }
    return probabilityA > probabilityB ? -1 : 1;
  });

  const anchorIndex = sortedImages?.findIndex(
    (image) =>
      image.frame_index === anchor?.frame_index &&
      image.camera_index === anchor?.camera_index
  );

  const previous = () => sortedImages?.[anchorIndex - 1];

  const next = () => sortedImages?.[anchorIndex + 1];

  const maxProbabilityUnannotated = () =>
    sortedImages?.find(
      (image) =>
        !image.annotations.some((annotation) =>
          ANNOTATION_TYPES[targetType].includes(annotation.type)
        ) &&
        (anchor === null ||
          image.frame_index !== anchor?.frame_index ||
          image.camera_index !== anchor?.camera_index)
    );

  const start = () => {
    if (images.isLoading) {
      return undefined;
    }
    return (
      maxProbabilityUnannotated() ?? sortedImages?.[0] ?? images?.data?.[0]
    );
  };

  useEffect(() => {
    if (measurementName && frameIndex && cameraIndex && !anchor) {
      setAnchor({
        measurementName: measurementName,
        frame_index: frameIndex,
        camera_index: cameraIndex,
      });
    }
  }, [measurementName, frameIndex, cameraIndex, anchor]);

  return {
    isLoading: images.isLoading,
    anchor,
    setAnchor,
    previous,
    next,
    maxProbabilityUnannotated,
    start,
  };
};
