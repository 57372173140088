import useBackend from "./useBackend";

export const useStitchedImageData = (
  { measurementName, fromFrameIndex, toFrameIndex, cameraIndex },
  options
) =>
  useBackend(measurementName ? `/images/image-data/stitched` : null, {
    query: {
      measurement_name: measurementName,
      from_frame_index: fromFrameIndex,
      to_frame_index: toFrameIndex,
      camera_index: cameraIndex,
    },
    options: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      focusThrottleInterval: 1000 * 60,
      ...options,
    },
  });
