import { Text, Tooltip } from "@chakra-ui/react";
import useImages from "../../backend/hooks/useImages";
import { Loader } from "../../components/Loader";
import { ANNOTATION_TYPES, TARGETS } from "./annotationTypes";

export const RemainingImages = ({ measurementName, targetType }) => {
  const images = useImages({
    measurementName,
    requiresAnnotation: true,
    targets: TARGETS[targetType],
  });

  if (images.isLoading) {
    return <Loader isLoading />;
  }

  const relevantImages = images.data.filter(
    (image) =>
      image.predictions.filter(
        (prediction) =>
          TARGETS[targetType].includes(prediction.target) &&
          prediction.probability > 0.5
      ).length >= 1
  );

  const annotated = relevantImages.filter(
    (image) =>
      image.annotations &&
      image.annotations.length >= 1 &&
      image.annotations.some((annotation) =>
        ANNOTATION_TYPES[targetType].includes(annotation.type)
      )
  );

  return (
    <Text fontWeight="bold" fontSize="xxx-large" flex={1} textAlign="center">
      <Tooltip label="Remaining images to annotate">
        {(annotated.length <= relevantImages.length
          ? relevantImages.length - annotated.length
          : 0
        ).toString()}
      </Tooltip>
    </Text>
  );
};
