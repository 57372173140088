import React from "react";
import styled from "styled-components";
import getSizeUnit from "../../../core/getSizeUnit";
import ProgressBar from "../../ProgressBar/ProgressBar";
import RetryButton from "../../RetryButton";
import StatusIcon from "../../StatusIcon";
import { triggerOnEvent } from "../../../core/events";
import objectMatchFilter from "../../../core/objectMatch";
import { filter, first } from "../../../core/functional";
import Wrapper from "./Wrapper";
import StatusText from "./StatusText";
import { theme } from "../../../theme";
const Name = styled.span `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  font-size: ${theme.font.size.medium};
`;
const FileSize = styled.span `
  margin-left: ${theme.spacing.small};
  font-weight: bold;
  font-size: ${theme.font.size.small};
  color: ${theme.color.grey};
`;
const FileRow = ({ name, size, status, progress, dispatch, id, onClick, fileData, message, }) => (React.createElement(Wrapper, { status: status, onClick: triggerOnEvent(onClick, first(filter(fileData, objectMatchFilter("localFileId", id)))) },
    React.createElement("div", { style: {
            display: "flex",
            alignItems: "baseline",
        } },
        React.createElement(StatusIcon, { status: status }),
        React.createElement(Name, null, name),
        React.createElement("div", { style: { marginLeft: "auto" } },
            React.createElement(StatusText, { status: status, message: message }),
            React.createElement(RetryButton, { dispatch: dispatch, fileId: id, status: status })),
        React.createElement(FileSize, null, `${getSizeUnit(size).size} ${getSizeUnit(size).short}`)),
    React.createElement(ProgressBar, { progress: progress, status: status })));
export default FileRow;
