import React from "react";
import { Button } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import useImage from "../../backend/hooks/useImage.js";

export const CreateInspectionButton = ({ imageId }) => {
  const image = useImage(imageId);

  if (image.isLoading) {
    return (
      <Button isLoading colorScheme="green">
        Create inspection
      </Button>
    );
  }

  if (image.data?.inspection) {
    return (
      <Button as={RouterLink} to={`/inspections/${image.data?.id}`}>
        See inspection
      </Button>
    );
  }

  return (
    <Button colorScheme="green" onClick={() => image.createInspection({})}>
      Create inspection
    </Button>
  );
};
