import { useNavigate } from "./useNavigate";
import useImages from "../../backend/hooks/useImages";
import { useCarDirection } from "./useCarDirection";
import { useEffect } from "react";
import {
  DAMAGE,
  SOFT_WIRE_DAMAGE,
  ANNOTATION_TYPES,
  BRIEFLY_CHECKED_NOT_DAMAGE,
  BRIEFLY_CHECKED_NOT_JOINT,
  DAMAGE_KEYBOARD_MAP,
  JOINT_KEYBOARD_MAP,
} from "./annotationTypes.js";

const preventArrowDefault = (event) => {
  if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key)) {
    event.preventDefault();
  }
};

export const useKeyBindings = (
  targetType,
  measurementName,
  frameIndex,
  cameraIndex,
  probabilityQueue,
  showRequiresAnnotationOnly
) => {
  const navigate = useNavigate();
  const imagesToAnnotate = useImages({
    measurementName,
    requiresAnnotation: true,
  });
  const image = useImages({
    measurementName,
    frameIndex,
    cameraIndex,
  }).useImage();

  const carDirection = useCarDirection(measurementName);
  const frameIndexIncrement = carDirection.data === "A" ? -1 : 1;

  const annotationKeyboardMap =
    targetType === DAMAGE || targetType === SOFT_WIRE_DAMAGE
      ? DAMAGE_KEYBOARD_MAP
      : JOINT_KEYBOARD_MAP;

  useEffect(() => {
    const atLeastBrieflyChecked = () => {
      const hasTargetAnnotation = image.data?.annotations?.some((annotation) =>
        ANNOTATION_TYPES[targetType].includes(annotation.type)
      );
      console.log(image);
      if (!hasTargetAnnotation && image?.data?.requires_annotation === true) {
        if (targetType === DAMAGE || targetType === SOFT_WIRE_DAMAGE) {
          image?.createAnnotation(BRIEFLY_CHECKED_NOT_DAMAGE);
        } else {
          image?.createAnnotation(BRIEFLY_CHECKED_NOT_JOINT);
        }
      }
    };

    const sortedImagesToAnnotateWithSameCamera =
      showRequiresAnnotationOnly &&
      imagesToAnnotate.data
        ?.filter((image) => image?.camera_index === cameraIndex)
        ?.sort((imageA, imageB) => imageA.frame_index - imageB.frame_index);

    const imageIndex =
      sortedImagesToAnnotateWithSameCamera &&
      sortedImagesToAnnotateWithSameCamera.findIndex(
        (image) => image.frame_index === frameIndex
      );

    document.onkeydown = (event) => {
      switch (event.key) {
        case "w":
        case "ArrowUp":
          preventArrowDefault(event);
          atLeastBrieflyChecked();
          if (showRequiresAnnotationOnly) {
            const nextImage =
              sortedImagesToAnnotateWithSameCamera?.[
                imageIndex + frameIndexIncrement
              ];
            if (nextImage !== undefined) {
              navigate.push(
                nextImage.measurement_name,
                nextImage.frame_index,
                nextImage.camera_index
              );
            }
          } else {
            navigate.push(
              measurementName,
              frameIndex + frameIndexIncrement,
              cameraIndex
            );
          }
          break;
        case "s":
        case "ArrowDown":
          preventArrowDefault(event);
          atLeastBrieflyChecked();
          if (showRequiresAnnotationOnly) {
            const previousImage =
              sortedImagesToAnnotateWithSameCamera?.[
                imageIndex - frameIndexIncrement
              ];
            if (previousImage !== undefined) {
              navigate.push(
                previousImage.measurement_name,
                previousImage.frame_index,
                previousImage.camera_index
              );
            }
          } else {
            navigate.push(
              measurementName,
              frameIndex - frameIndexIncrement,
              cameraIndex
            );
          }
          break;
        case "d":
        case "ArrowRight":
          preventArrowDefault(event);
          atLeastBrieflyChecked();
          navigate.push(
            measurementName,
            frameIndex,
            Math.min(cameraIndex + 1, 212)
          );
          break;
        case "a":
        case "ArrowLeft":
          preventArrowDefault(event);
          atLeastBrieflyChecked();
          navigate.push(
            measurementName,
            frameIndex,
            Math.max(cameraIndex - 1, 211)
          );
          break;
        case "e":
          const nextAnchor = probabilityQueue.next();
          if (nextAnchor) {
            atLeastBrieflyChecked();
            probabilityQueue.setAnchor(nextAnchor);
            navigate.push(
              nextAnchor.measurement_name,
              nextAnchor.frame_index,
              nextAnchor.camera_index
            );
          }
          break;
        case "q":
          const previousAnchor = probabilityQueue.previous();
          if (previousAnchor) {
            atLeastBrieflyChecked();
            probabilityQueue.setAnchor(previousAnchor);
            navigate.push(
              previousAnchor.measurement_name,
              previousAnchor.frame_index,
              previousAnchor.camera_index
            );
          }
          break;
        case "r":
          const anchor = probabilityQueue.maxProbabilityUnannotated();
          if (anchor) {
            atLeastBrieflyChecked();
            probabilityQueue.setAnchor(anchor);
            navigate.push(
              anchor.measurement_name,
              anchor.frame_index,
              anchor.camera_index
            );
          }
          break;
        case "Backspace":
          if (image.data !== undefined && image.data.annotations.length) {
            image.deleteAnnotation(image.data.annotations.slice(-1).pop().id);
          }
          break;
        default:
          if (image.data !== undefined) {
            Object.keys(annotationKeyboardMap)
              .filter((key) => key === event.key)
              .map((key) => annotationKeyboardMap[key])
              .forEach((annotationType) => {
                if (
                  !image?.data?.annotations
                    ?.map((annotation) => annotation.type)
                    ?.includes(annotationType)
                ) {
                  image.createAnnotation(annotationType);
                }
              });
          }
      }
    };

    return () => {
      document.onkeydown = null;
    };
  });
};
