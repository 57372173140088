import { Loader } from "../../components/Loader.jsx";
import { Damage } from "./Damage.jsx";
import { Box, Heading } from "@chakra-ui/react";
import { HTTPError } from "../../components/HTTPError/index.jsx";
import { PageButtons } from "../../components/PageButtons.jsx";
import { useLimitAndOffset } from "../../state/useLimitAndOffset.js";

export const InspectionList = ({ inspectionImages, filter }) => {
  const limitAndOffset = useLimitAndOffset();

  if (inspectionImages.isLoading) {
    return <Loader isLoading />;
  }

  if (inspectionImages.error) {
    return (
      <HTTPError
        error={inspectionImages.error}
        description="Failed to load inspections"
      />
    );
  }

  return (
    <Box width="100%">
      {inspectionImages.data.length === 0 ? (
        <Heading textAlign="center" size="md" marginBottom="5">
          There are no damages to show
        </Heading>
      ) : null}

      {inspectionImages.data.filter(filter).map((image, index) => (
        <Damage
          key={image.id}
          initialImage={image}
          onChange={(newImage) => {
            inspectionImages.data[index] = newImage;
            inspectionImages.mutate(inspectionImages.data, false);
          }}
          onDelete={() => {
            inspectionImages.data.splice(index, 1);
            inspectionImages.mutate(inspectionImages.data, false);
          }}
        />
      ))}

      <PageButtons
        isDisabledNext={inspectionImages.data.length < limitAndOffset.limit}
      />
    </Box>
  );
};
