import React, { useId } from "react";
import { useState } from "../../store/ContextProvider";
import { Label } from "./Label";
import { Wrapper } from "./Wrapper";
import { getEventFiles, getEventTargetFiles, preventDefault } from "./events";
import { appendFiles } from "../../store/appendFiles";
import { START_DRAG, STOP_DRAG } from "../../store/actionTypes";
import { theme } from "../../theme";
const defaultStyles = {
    fontWeight: 900,
    color: theme.color.black,
    colorDragging: theme.color.white,
    backgroundColor: theme.color.blueLight,
    backgroundColorDragging: theme.color.blue,
    fontSize: theme.font.size.big,
    border: `1px solid ${theme.color.primary}`,
    borderTopLeftRadius: theme.border.radius.primary,
    borderTopRightRadius: theme.border.radius.primary,
    borderBottomRightRadius: theme.border.radius.primary,
    borderBottomLeftRadius: theme.border.radius.primary,
    padding: theme.spacing.medium,
};
export const FileDropzone = () => {
    const { state, dispatch } = useState();
    const id = useId();
    const borderRadius = `calc(${theme.border.radius.primary} - 1px)`;
    const styles = {
        border: "none",
        borderTopRightRadius: borderRadius,
        borderTopLeftRadius: borderRadius,
        borderBottomRightRadius: state.files?.length > 0 ? 0 : borderRadius,
        borderBottomLeftRadius: state.files?.length > 0 ? 0 : borderRadius,
    };
    return (React.createElement(Wrapper, { dragging: state.isDragging, onDragEnter: (_) => dispatch({ type: START_DRAG }), onDragExit: (_) => dispatch({ type: STOP_DRAG }), onDragOver: preventDefault, onDrop: (event) => dispatch(appendFiles(getEventFiles(preventDefault(event)))), styles: {
            ...defaultStyles,
            ...styles,
        }, className: "hide-scrollbar" },
        React.createElement(Label, { htmlFor: id, styles: {
                ...defaultStyles,
                ...styles,
            } },
            state.isDragging
                ? "Now is the time! Drop the files."
                : "Drop files here or click to browse",
            React.createElement("input", { type: "file", onChange: (event) => dispatch(appendFiles(getEventTargetFiles(preventDefault(event)))), id: id, style: { display: "none" }, multiple: true }))));
};
