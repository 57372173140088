import { Box, Select, VStack } from "@chakra-ui/react";
import { NearbyInspections } from "../../components/NearbyInspections.jsx";
import useImage from "../../backend/hooks/useImage.js";
import { DamageWrapper } from "../../components/Damage/DamageWrapper.jsx";
import { DamageHeader } from "../../components/Damage/DamageHeader.jsx";
import { DamageFooter } from "../../components/Damage/DamageFooter.jsx";
import { InspectionImage } from "../../components/InspectionImage";
import { SearchLink } from "../../components/Damage/buttons/Search.jsx";
import { DamageComment } from "../../components/DamageComment.jsx";
import { DamageContent } from "../../components/Damage/DamageContent.jsx";

// TODO: Remove this if it's still working
// import "react-medium-image-zoom/dist/styles.css";

export const Damage = ({ initialImage, onChange }) => {
  const image = useImage(initialImage.id, { fallbackData: initialImage });
  const inspection = image.data.inspection;

  const updateInspection = (changes) => {
    const newInspection = { ...inspection, ...changes };
    image.updateInspection(newInspection);
    onChange({
      ...image.data,
      inspection: newInspection,
    });
  };

  return (
    <DamageWrapper>
      <DamageHeader image={image.data} />

      <DamageContent>
        <InspectionImage image={image} />

        <VStack width="100%" marginLeft="3">
          <Select
            value={inspection.action_type || ""}
            onChange={(event) =>
              updateInspection({ action_type: event.target.value })
            }
            placeholder="Select an action"
            size="sm"
            borderRadius="md"
          >
            <option value="immediate_inspection">
              1. Immediate inspection
            </option>
            <option value="inspection_within_three_weeks">
              2. Inspection within three weeks
            </option>
            <option value="inspection_within_one_year">
              3. Inspection within one year
            </option>
            <option value="no_inspection">4. No inspection</option>
          </Select>

          <DamageComment
            value={inspection?.official_comment || ""}
            onChange={(event) =>
              updateInspection({ official_comment: event.target.value })
            }
          />
        </VStack>
      </DamageContent>

      <Box marginLeft="3" marginRight="3" marginBottom="2">
        <NearbyInspections
          status="warning"
          filter={(otherInspectionImage) =>
            image.data.id !== otherInspectionImage.id
          }
          imageId={image.data.id}
          official={true}
        />
      </Box>

      <DamageFooter inspection={inspection}>
        <SearchLink image={image} />
      </DamageFooter>
    </DamageWrapper>
  );
};
