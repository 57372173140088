import { HStack, Tooltip } from "@chakra-ui/react";

export const Gps = ({ position }) => (
  <HStack spacing={3}>
    <Tooltip label="latitude">
      {position?.coordinates[1]?.toString() ?? "?"}
    </Tooltip>
    <Tooltip label="longitude">
      {position?.coordinates[0]?.toString() ?? "?"}
    </Tooltip>
  </HStack>
);
