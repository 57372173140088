import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AnnotationView, AnnotateRedirect } from "./Annotate";
import DeletedInspection from "./Inspection/DeletedInspection";
import Inspection from "./Inspection";
import { Damages } from "./Damages";
import { InternalReview } from "./InternalReview";
import { Measurements } from "./Measurements";
import { UserStatistics } from "./UserStatistics";
import InspectionMetrics from "./InspectionMetrics";
import { DamageMap } from "./DamageMap";
import { SearchView } from "./ImageSearch";
import { PositionUpload } from "./PositionUpload";
import { NotFound404 } from "./Error/404";
import { NavBar } from "./NavBar";
import { MeasurementInspection } from "./MeasurementInspection/index.js";
import { ImageView } from "./Image/index.js";

export const Views = () => (
  <BrowserRouter>
    <NavBar />
    <Switch>
      <Route exact path="/" component={Damages} />
      <Route path="/damages" component={Damages} />

      <Route
        path="/images/:measurementName/:frameIndex/:cameraIndex"
        component={AnnotationView.Component}
      />
      <Route
        path="/images/:measurementName/:frameIndex?"
        component={AnnotateRedirect}
      />

      <Route
        path="/inspection/:measurementName/:index"
        component={MeasurementInspection.Component}
      />

      <Route path="/image/:id" component={ImageView.Component} />

      <Route exact path="/inspections/deleted" component={DeletedInspection} />
      <Route path="/inspections/:id?" component={Inspection} />
      <Route path="/inspection-metrics" component={InspectionMetrics} />

      <Route exact path="/user/statistics" component={UserStatistics} />

      <Route path="/damage-map" component={DamageMap} />

      <Route path="/image-search" component={SearchView.Component} />

      <Route path="/internal-review" component={InternalReview} />

      <Route path="/measurements" component={Measurements} />

      <Route path="/positions" component={PositionUpload} />
      <Route path="*" component={NotFound404} />
    </Switch>
  </BrowserRouter>
);
