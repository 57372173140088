import { useCallback, useRef } from "react";

export const useDebounce = () => {
  const debounceRef = useRef(null);

  const debounce = useCallback((fn, { timeout }) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      fn();
    }, timeout);
  }, []);

  return debounce;
};
