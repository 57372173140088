import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import useImageData from "../../backend/hooks/useImageData.js";
import useImage from "../../backend/hooks/useImage.js";
import { Flex } from "@chakra-ui/react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { PositionFooter } from "../../components/ImageScroll/PositionFooter.jsx";
const Component = () => {
  const { id } = useParams();
  const { data } = useImageData(id);
  const image = useImage(id)?.data;

  if (!data) return "loading image...";

  return (
    <Flex
      top="64px"
      left="0"
      position="absolute"
      height="calc(100vh - 64px)"
      width="full"
      overflow="hidden"
      backgroundColor="gray.600"
    >
      {" "}
      <TransformWrapper initialScale={1}>
        <TransformComponent
          wrapperStyle={{
            width: "100%",
            height: "calc(100% - 100px)",
          }}
          contentStyle={{ width: "100%", height: "100%" }}
        >
          <img src={data} alt={id} style={{ width: "100%", height: "100%" }} />
        </TransformComponent>
      </TransformWrapper>
      <PositionFooter imageData={image} showCameraIndex={true} />
    </Flex>
  );
};

export const ImageView = { Component, at: (image) => `/image/${image?.id}` };
