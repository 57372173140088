import { Flex, Heading, HStack, Tooltip, Spacer } from "@chakra-ui/react";
import { Position } from "../Position";

export const DamageHeader = ({ image }) => {
  return (
    <Flex width="full" paddingTop="3" paddingLeft="3" paddingRight="3">
      <Heading size="sm" marginTop="0">
        <HStack>
          <Tooltip label="measurement_name">{image?.measurement_name}</Tooltip>
          <span>/</span>
          <Tooltip label="frame_index">
            {image?.frame_index?.toString()}
          </Tooltip>
          <span>/</span>
          <Tooltip label="camera_index">
            {image?.camera_index?.toString()}
          </Tooltip>
        </HStack>
      </Heading>

      <Spacer />
      <Heading size="sm" marginTop="0">
        <Position image={image} />
      </Heading>
    </Flex>
  );
};
