import { useConfig } from "../../state/ConfigProvider";
import { Gps } from "./Gps";
import { Kmm } from "./Kmm";

export const Position = ({ image }) => {
  const config = useConfig();

  if (config?.position?.format === "gps") {
    return <Gps position={image?.closest_position} />;
  } else {
    return <Kmm position={image?.closest_position} />;
  }
};
