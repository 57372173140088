import { useEffect, useState, useMemo, useCallback } from "react";

import { useLocation, useHistory } from "react-router-dom";

import {
  Button,
  ButtonGroup,
  Box,
  Switch,
  FormControl,
  FormLabel,
  Heading,
} from "@chakra-ui/react";

import { DAMAGE, SOFT_WIRE_DAMAGE, JOINT } from "./annotationTypes.js";

const useMirrorStateInUrl = (state) => {
  const history = useHistory();
  const location = useLocation();

  const serializeUrlParameter = (state) => (key) => {
    const value = state[key];

    if (Array.isArray(value)) {
      const urlArray = value.join(",");
      return `${key}=${urlArray}`;
    }

    return `${key}=${value}`;
  };

  useEffect(() => {
    const nextUrl = `?${Object.keys(state)
      .map(serializeUrlParameter(state))
      .join("&")}`;

    if (["?", "?&"].includes(nextUrl)) {
      history.replace(location.pathname);
    } else {
      history.replace(nextUrl);
    }
  }, [state, history, location.pathname]);
};

export const useSettings = () => {
  // NOTE: Not so scalable but easy solution...

  const location = useLocation();

  const parsedQuery = new URLSearchParams(location.search);

  const [settings, setSettings] = useState({
    targetType: parsedQuery.get("targetType") || DAMAGE,
    displayPredictions: Boolean(parsedQuery.get("displayPredictions")) || true,
    showRequiresAnnotationOnly: Boolean(
      parsedQuery.get("showRequiresAnnotationOnly") || true
    ),
  });

  useMirrorStateInUrl(settings);

  const set = useCallback((update) => {
    setSettings((state) => ({ ...state, ...update }));
  }, []);

  return useMemo(() => ({ ...settings, set }), [settings, set]);
};

export const Settings = ({ settings }) => {
  return (
    <Box borderRadius={"md"} backgroundColor={"gray.50"} padding={5} flex={1}>
      <Heading size="sm" marginBottom={3}>
        Settings
      </Heading>

      <ButtonGroup
        isAttached
        variant="outline"
        colorScheme="blue"
        size="sm"
        marginBottom={3}
      >
        <Button
          padding="10px"
          onClick={() => settings.set({ targetType: DAMAGE })}
          isActive={settings.targetType === DAMAGE}
        >
          Damages
        </Button>
        <Button
          padding="10px"
          onClick={() => settings.set({ targetType: SOFT_WIRE_DAMAGE })}
          isActive={settings.targetType === SOFT_WIRE_DAMAGE}
        >
          Soft Wire Damages
        </Button>
        <Button
          padding="10px"
          onClick={() => settings.set({ targetType: JOINT })}
          isActive={settings.targetType === JOINT}
        >
          Joints
        </Button>
      </ButtonGroup>

      <FormControl display={"flex"}>
        <FormLabel htmlFor="displayPredictions">
          Display predictions in image
        </FormLabel>
        <Switch
          id="displayPredictions"
          isChecked={settings.displayPredictions}
          onChange={({ target }) =>
            settings.set({ displayPredictions: target.checked })
          }
          marginLeft={"auto"}
        />
      </FormControl>
      <FormControl display={"flex"}>
        <FormLabel htmlFor="showRequiresAnnotationOnly">
          Only show images that require annotation
        </FormLabel>
        <Switch
          id="showRequiresAnnotationOnly"
          isChecked={settings.showRequiresAnnotationOnly}
          onChange={({ target }) =>
            settings.set({ showRequiresAnnotationOnly: target.checked })
          }
          marginLeft={"auto"}
        />
      </FormControl>
    </Box>
  );
};
