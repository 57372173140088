import { Container, VStack } from "@chakra-ui/react";

import { match } from "@nextml/lodestar";

import SearchForm from "./SearchForm.jsx";
import SearchResults from "./SearchResults.jsx";

export const Component = () => {
  return (
    <Container maxW="container.md">
      <VStack spacing="10" marginTop="10">
        <SearchForm />
        <SearchResults />
      </VStack>
    </Container>
  );
};

export const SearchView = {
  Component,
  at: match({
    kmm: ({ kilometer, meter, trackSection, trackLane }) => {
      return `/image-search?searchBy=kmm&trackSection=${trackSection}&kilometer=${kilometer}&meter=${meter}${
        trackLane ? `&trackLane=${trackLane}` : ""
      }`;
    },
  }),
};
