import React from "react";
import styled from "styled-components";
import { FAIL, UPLOADING } from "../../../core/constants";
import { theme } from "../../../theme";
const getStatusText = (status, message) => {
    switch (status) {
        case UPLOADING: {
            return "Uploading file...";
        }
        case FAIL: {
            return message ? message : `Failed to upload file`;
        }
        default: {
            return "";
        }
    }
};
const Wrapper = styled.span `
  margin-left: ${theme.spacing.small};
`;
const StatusText = ({ status, message }) => {
    return React.createElement(Wrapper, null, getStatusText(status, message));
};
export default StatusText;
