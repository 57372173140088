import { useEffect, useMemo } from "react";

import {
  Container,
  Center,
  Spinner,
  Flex,
  Spacer,
  Button,
  VStack,
} from "@chakra-ui/react";

import { WireHeading } from "./WireHeading.jsx";
import { WireImage } from "./WireImage.jsx";
import { CameraKeyBindings } from "./CameraKeyBindings.jsx";
import { AnnotationKeyBindings } from "./AnnotationKeyBindings.jsx";
import { AnnotationList } from "./AnnotationList.jsx";
import { PredictionsList } from "./PredictionsList.jsx";
import { CreateInspectionButton } from "./CreateInspectionButton.jsx";
import { useBufferedBase64Images } from "./useBufferedBase64Images.js";
import { useNavigate } from "./useNavigate.js";
import { useKeyBindings } from "./useKeyBindings.js";
import { useProbabilityQueue } from "./useProbabilityQueue.js";
import { NearbyInspections } from "../../components/NearbyInspections.jsx";
import { useUrlState } from "../../state/useUrlState.js";
import { DAMAGE, SOFT_WIRE_DAMAGE, JOINT } from "./annotationTypes.js";
import useImages from "../../backend/hooks/useImages.js";
import { Loader } from "../../components/Loader.jsx";
import { HoverLayer } from "../../components/HoverLayer.jsx";
import { RemainingImages } from "./RemainingImages.jsx";

import { Settings, useSettings } from "./Settings.jsx";
import { SearchView } from "../ImageSearch/index.jsx";

import { useNavigate as useViewNavigate } from "../../utils/useNavigate.js";

export const AnnotateRedirect = ({ match }) => {
  const measurementName = match.params.measurementName;

  const navigate = useNavigate();

  const { data: targetType } = useUrlState("targetType", DAMAGE);

  const probabilityQueue = useProbabilityQueue({
    measurementName,
    targetType,
  });

  useEffect(() => {
    if (!probabilityQueue.isLoading && measurementName) {
      const startPosition = probabilityQueue.start();

      if (startPosition) {
        navigate.replace(
          measurementName,
          startPosition.frame_index,
          startPosition.camera_index
        );
      }
    }
  }, [measurementName, probabilityQueue, navigate]);

  if (!probabilityQueue.isLoading) {
    return (
      <Container maxW="container.md">
        <Center height="100px">
          No images with target type "{targetType}" found.
        </Center>
      </Container>
    );
  }

  return <Loader />;
};

const useProbabilityQueues = ({ measurementName, frameIndex, cameraIndex }) => {
  const softWireQueue = useProbabilityQueue({
    measurementName,
    frameIndex,
    cameraIndex,
    targetType: SOFT_WIRE_DAMAGE,
  });

  const damageQueue = useProbabilityQueue({
    measurementName,
    frameIndex,
    cameraIndex,
    targetType: DAMAGE,
  });

  const jointQueue = useProbabilityQueue({
    measurementName,
    frameIndex,
    cameraIndex,
    targetType: JOINT,
  });

  return useMemo(
    () => ({
      [DAMAGE]: damageQueue,
      [SOFT_WIRE_DAMAGE]: softWireQueue,
      [JOINT]: jointQueue,
    }),
    [damageQueue, softWireQueue, jointQueue]
  );
};

const useBackedUpImage = ({ measurementName, frameIndex, cameraIndex }) => {
  const mainImage = useImages({
    measurementName,
    requiresAnnotation: true,
  }).useImage({
    frameIndex,
    cameraIndex,
  });

  const backupImage = useImages(
    mainImage.isLoading || mainImage.data
      ? null
      : { measurementName, frameIndex, cameraIndex }
  ).useImage({});

  return useMemo(
    () => (mainImage.isLoading || mainImage.data ? mainImage : backupImage),
    [mainImage, backupImage]
  );
};

export const Component = (props) => {
  const measurementName = props.match.params.measurementName;
  const frameIndex = parseInt(props.match.params.frameIndex);
  const cameraIndex = parseInt(props.match.params.cameraIndex);

  const navigate = useViewNavigate();

  const settings = useSettings();

  const probabilityQueues = useProbabilityQueues({
    measurementName,
    frameIndex,
    cameraIndex,
  });

  const image = useBackedUpImage({
    measurementName,
    frameIndex,
    cameraIndex,
  });

  const probabilityQueue = probabilityQueues[settings.targetType];

  const buffer = useBufferedBase64Images(
    measurementName,
    frameIndex,
    cameraIndex,
    settings.targetType,
    settings.showRequiresAnnotationOnly
  );

  useKeyBindings(
    settings.targetType,
    measurementName,
    frameIndex,
    cameraIndex,
    probabilityQueue,
    settings.showRequiresAnnotationOnly
  );

  if (image.error) {
    return (
      <Container maxW="container.md">
        <Center height="100px">{image.error}</Center>
      </Container>
    );
  }

  if (probabilityQueue.isLoading || image.isLoading) {
    return (
      <Container maxW="container.md">
        <Center height="100px">
          <Spinner />
        </Center>
      </Container>
    );
  }

  return (
    <Flex direction="column">
      <WireImage
        imageId={image.data.id}
        targetType={settings.targetType}
        fallbackData={buffer.current?.[image.data.id]}
        displayPredictions={settings.displayPredictions}
      >
        <HoverLayer isVisible>
          <WireHeading imageId={image.data.id} />
        </HoverLayer>
        <HoverLayer bottom="0" top="none" isVisible>
          <CameraKeyBindings
            measurementName={measurementName}
            frameIndex={frameIndex}
            cameraIndex={cameraIndex}
          />
        </HoverLayer>
      </WireImage>

      <AnnotationKeyBindings
        imageId={image.data.id}
        targetType={settings.targetType}
      />

      <NearbyInspections
        imageId={image.data.id}
        filter={(inspection) => inspection.measurement_name === measurementName}
        status={"info"}
        distance={1}
      />

      <Flex
        paddingLeft="10"
        paddingRight="10"
        height="50px"
        borderBottom="1px solid"
        borderTop="1px solid"
        borderColor="gray.100"
      >
        <AnnotationList imageId={image.data.id} />
        <Spacer />
        <PredictionsList imageId={image.data.id} />
      </Flex>

      <Flex padding="10" justifyContent="center">
        <Settings settings={settings} />

        <RemainingImages
          targetType={settings.targetType}
          measurementName={measurementName}
        />

        <VStack flex={1} direction={"column"} alignItems={"left"}>
          <CreateInspectionButton imageId={image.data.id} />

          <Button
            colorScheme="blue"
            onClick={() => {
              return navigate.to(
                SearchView.at({
                  kmm: {
                    trackSection: image.data.closest_position.track_section,
                    kilometer: image.data.closest_position.kilometer,
                    meter: image.data.closest_position.meter,
                    trackLane: image.data.closest_position.track_lane,
                  },
                })
              );
            }}
          >
            Search
          </Button>
        </VStack>
      </Flex>
    </Flex>
  );
};

export const AnnotationView = {
  Component,
  at: ({ measurementName, frameIndex, cameraIndex }) =>
    `/images/${measurementName}/${frameIndex}/${cameraIndex}`,
};
