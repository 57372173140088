import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useRef } from "react";
import useImages from "../../backend/hooks/useImages.js";
import { TARGETS } from "./annotationTypes.js";
import { useConfig } from "../../state/ConfigProvider.jsx";

const maxProbability = (image) =>
  Math.max(...image.predictions.map((prediction) => prediction.probability));

export const useBufferedBase64Images = (
  measurementName,
  frameIndex,
  cameraIndex,
  targetType,
  showRequiresAnnotationOnly
) => {
  const config = useConfig();
  const { keycloak } = useKeycloak();
  const images = useImages({
    measurementName,
    requiresAnnotation: true,
    targets: TARGETS[targetType],
  });
  const imagesNotToAnnotate = useImages(
    showRequiresAnnotationOnly === "true"
      ? null
      : {
          measurementName,
          fromFrameIndex: frameIndex - 1,
          toFrameIndex: frameIndex + 1,
          requiresAnnotation: false,
        }
  );
  const startedDownload = useRef([]);
  const buffer = useRef({});
  useEffect(() => {
    if (images.data) {
      const nearby = images.data?.filter(
        (image) => Math.abs(image.frame_index - frameIndex) <= 3
      );

      const sortedByProbability = images.data
        ?.filter((image) => image.requires_annotation)
        ?.sort((imageA, imageB) => {
          const probabilityA = maxProbability(imageA);
          const probabilityB = maxProbability(imageB);
          if (probabilityA === probabilityB) {
            return 0;
          }
          return probabilityA > probabilityB ? -1 : 1;
        });

      const currentIndex = sortedByProbability?.findIndex(
        (image) =>
          image.frame_index === frameIndex && image.camera_index === cameraIndex
      );

      const downloadImages = [
        ...nearby,
        sortedByProbability?.[currentIndex - 1],
        sortedByProbability?.[currentIndex + 1],
        ...(imagesNotToAnnotate?.data ?? []),
      ].filter(
        (image) =>
          image !== undefined && !startedDownload.current.includes(image.id)
      );

      startedDownload.current.push(...downloadImages.map((image) => image.id));

      const worker = new window.Worker("/prefetch_image.js");
      if (downloadImages && downloadImages.length >= 1) {
        downloadBase64Images(
          worker,
          downloadImages,
          keycloak.token,
          buffer,
          config
        );
      }
      return () => {
        setTimeout(() => worker.terminate(), 5000);
      };
    }
  }, [
    images?.data,
    imagesNotToAnnotate?.data,
    keycloak?.token,
    frameIndex,
    cameraIndex,
    showRequiresAnnotationOnly,
    config,
  ]);

  return buffer;
};

const downloadBase64Images = (
  worker,
  images,
  keycloakToken,
  buffer,
  config
) => {
  worker.onmessage = (event) =>
    event.data.forEach((message) => {
      buffer.current[message.id] = message.base64_image;
    });
  worker.postMessage({
    images: images,
    backend: config.api.endpoint,
    keycloakToken,
  });
};
