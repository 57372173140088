import { useRef } from "react";
import {
  Flex,
  HStack,
  Tooltip,
  Heading,
  Button,
  Box,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "../../utils/useNavigate.js";
import { AnnotationView } from "../../views/Annotate/index.jsx";
import { Position } from "../Position/index.jsx";
import { useConfig } from "../../state/ConfigProvider.jsx";

export const PositionFooter = ({ imageData, showCameraIndex = false }) => {
  const navigate = useNavigate();
  const {
    position: { format: positionFormat },
  } = useConfig();

  const stickyImage = useRef();
  if (imageData !== undefined) {
    stickyImage.current = imageData;
  }

  if (stickyImage.current === undefined) return null;

  return (
    <Flex
      zIndex={100}
      width="100%"
      justifyContent={"center"}
      bottom={positionFormat === "gps" ? "25px" : "30px"}
      position="absolute"
    >
      <Box flex={1} />
      <Heading size={positionFormat === "gps" ? "md" : "lg"} color="white">
        <Stack
          direction={positionFormat === "gps" ? "column" : "row"}
          alignItems="center"
          spacing={positionFormat === "gps" ? 2 : 10}
        >
          <MeasurementPosition
            image={stickyImage.current}
            showCameraIndex={showCameraIndex}
          />
          <Position image={stickyImage.current} />
        </Stack>
      </Heading>

      <Flex flex={1} marginTop={positionFormat === "gps" ? "10px" : "0px"}>
        <Button
          marginLeft={"auto"}
          marginRight={10}
          onClick={() => {
            navigate.to(
              AnnotationView.at({
                measurementName: stickyImage.current.measurement_name,
                cameraIndex: stickyImage.current.camera_index?.toString(),
                frameIndex: stickyImage.current.frame_index?.toString(),
              })
            );
          }}
        >
          Annotate
        </Button>
      </Flex>
    </Flex>
  );
};

const MeasurementPosition = ({ image, showCameraIndex }) => (
  <HStack spacing={2}>
    <Tooltip label="measurement_name">{image.measurement_name}</Tooltip>
    <span>/</span>
    <Tooltip label="frame_index">{image.frame_index?.toString()}</Tooltip>
    {showCameraIndex && (
      <>
        <span>/</span>
        <Tooltip label="camera_index">{image.camera_index?.toString()}</Tooltip>
      </>
    )}
  </HStack>
);
