import { HStack, Tooltip } from "@chakra-ui/react";

export const Kmm = ({ position }) => (
  <HStack>
    <Tooltip label="track_section">{position?.track_section ?? "?"}</Tooltip>
    <span>/</span>
    <Tooltip label="kilometer">
      {position?.kilometer?.toString() ?? "?"}
    </Tooltip>
    <span>/</span>
    <Tooltip label="meter">{position?.meter?.toString() ?? "?"}</Tooltip>
    <span>/</span>
    <Tooltip label="track_lane">{position?.track_lane ?? "?"}</Tooltip>
  </HStack>
);
