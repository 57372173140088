import { useEffect } from "react";
import { useColorMode } from "@chakra-ui/react";

const AutoToggleColorMode = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    if (colorMode === "dark") {
      toggleColorMode();
    }
  }, [colorMode, toggleColorMode]);
  return <></>;
};

export default AutoToggleColorMode;
